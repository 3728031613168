import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  SERVER_URL = 'http://localhost:8000/'

  constructor(private httpService: HttpClient) { }
  

  get(url:string) {
    return this.httpService.get(this.SERVER_URL + url);
  }

  post(url, body) {
    return this.httpService.post(this.SERVER_URL + url, body);
  }

}
